const theme = {
  // OneTrust colors, mostly
  colors: {
    primaryBlue: '#2d536f',
    complimentaryBlue: '#5481a3',
    accentGreen: '#99c24d',
    accentOrange: '#eab345',
    accentRed: '#fd5f54',
    black: '#323232',
    white: '#ffffff', // not official OneTrust
  },
  breakpoints: {
    sm: '576px',
    md: '768px',
    lg: '992px',
    xl: '1200px',
  },
  // typography styles - for keeping sizes consistent
  typography: {
    sizes: {
      p: {
        xs: '0.75rem',
        sm: '1rem',
        md: '1.25rem',
        lg: '1.5rem',
      },
    },
    // correspond with Google font actual weights for Roboto
    weights: {
      thin: '100',
      light: '300',
      regular: '400',
      medium: '500',
      bold: '700',
      black: '900',
    },
  },
}

// helper functions to make it easy to use theme properties around application
export const getBreakpoint = breakpoint => props =>
  props.theme.breakpoints[breakpoint]

export const getColor = color => props => props.theme.colors[color]

export const getFontSize = (element, size) => props =>
  props.theme.typography.sizes[element][size]

export const getFontWeight = weight => props =>
  props.theme.typography.weights[weight]

export default theme
