import hubIcon from '../images/icons/otm_hub.png';
import jobIcon from '../images/icons/otm_job.png';
import menuIcon from '../images/icons/otm_menu.png';
import promoStoreIcon from '../images/icons/otm_promo_store.png';
import ticketIcon from '../images/icons/otm_ticket.png';
import turnaroundIcon from '../images/icons/otm_turnaround.png';

const menuItems = [
  {
    name: 'menu',
    icon: menuIcon,
    text: 'Menu of Services',
    url: '/services',
    external: false,
  },
  {
    name: 'job',
    icon: jobIcon,
    text: 'Job Aids',
    url: '/help',
    external: false,
  },
  {
    name: 'ticketRequest',
    icon: ticketIcon,
    text: 'Marketing Request Ticket',
    url:
      'https://na34.salesforce.com/a0v/e?retURL=%2Fa0v%2Fo&RecordType=01261000000X40r&ent=01I61000001VbR4',
    external: true,
  },
  {
    name: 'turnTimes',
    icon: turnaroundIcon,
    text: 'Turn Times',
    url:
      'https://onetrusthomeloans.sharepoint.com/subsite/OneTrust%20University/OneTrust%20Marketing/Marketing%20Policies/Turn%20Times%20for%20Marketing%20Tickets.pdf',
    external: true,
  },
  {
    name: 'hub',
    icon: hubIcon,
    text: 'Hub',
    url: 'https://www.onetrusthub.com/',
    external: true,
  },
];

export default menuItems;
