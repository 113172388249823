import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { ThemeProvider, createGlobalStyle } from 'styled-components';
import { StaticQuery, graphql } from 'gatsby';

import theme from '../util/theme';

import Header from './Header';
import Footer from './Footer';

const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
  }

  html, body {
    font-family: 'Roboto', sans-serif;
    font-size: 10px;
    line-height: 1.4;

    margin: 0;
    padding: 0;
  }

  body {
    position: relative;

    min-height: 90vh;
  }

  h1, h2 {
    color: #99c24d;
  }

  h1 {
    font-size: 4rem;
  }

  h2 {
    font-size: 3.5rem;
  }

  h3 {
    font-size: 3rem;
  }

  a {
    text-decoration: none;
  }

  p {
    font-size: 1.6rem;
  }

  ul {
    margin: 0;
    padding: 0;
  }

  img {
    max-width: 100%;
  }
`;

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <Helmet
          title={data.site.siteMetadata.title}
          meta={[
            { name: 'description', content: 'OneTrust Marketing' },
            { name: 'keywords', content: 'onetrust' },
          ]}
          script={[
            {
              type: 'text/javascript',
              innerHTML: `window.fbAsyncInit = function() {
                //SDK loaded, initialize it
                FB.init({
                  appId: 'your-app-id',
                  xfbml: true,
                  version: 'v2.6',
                });
                //JS SDK initialized, now you can use it
                FB.XFBML.parse();
              };

              //load the JavaScript SDK
              (function(d, s, id) {
                var js,
                  fjs = d.getElementsByTagName(s)[0];
                if (d.getElementById(id)) {
                  return;
                }
                js = d.createElement(s);
                js.id = id;
                js.src = '//connect.facebook.net/en_US/sdk.js';
                fjs.parentNode.insertBefore(js, fjs);
              })(document, 'script', 'facebook-jssdk');`,
            },
          ]}
        >
          <html lang="en" />
        </Helmet>
        <ThemeProvider theme={theme}>
          <>
            <GlobalStyle />
            <Header />
            {children}
            <Footer />
          </>
        </ThemeProvider>
      </>
    )}
  />
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
