import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components/macro';
import robotoCondensed from 'typeface-roboto-condensed';

// import assets
import glyph from '../images/glyph.png';
import othlLogo from '../images/logos/othl-logo-white.svg';

import { getBreakpoint, getColor, getFontWeight } from '../util/theme';

import menuItems from '../data/menu';

import { Grid } from './Grid';

const HeaderWrapper = styled.header`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;

  background-color: ${getColor('primaryBlue')};
  background-image: url(${glyph});
  background-position: 0 center;
  background-repeat: no-repeat;

  border-top: 5px solid ${getColor('complimentaryBlue')};
  border-bottom: 5px solid ${getColor('accentGreen')};
`;

const HeaderContainer = styled(Grid)`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  margin: 0;
  padding: 20px 0;

  > a {
    display: flex;
    align-items: center;
    justify-content: center;

    margin: 0 0 15px 0;

    width: 100%;

    @media (min-width: ${getBreakpoint('lg')}) {
      width: auto;
    }

    img {
      width: 100%;

      padding: 25px;

      @media (min-width: ${getBreakpoint('md')}) {
        width: 250px;

        margin: 0;
        padding: 0;
      }
    }
  }
`;

const Menu = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  @media (min-width: ${getBreakpoint('md')}}) {
    justify-content: space-between;
  }

  a {
    color: ${getColor('white')};
    font-family: ${robotoCondensed};
    line-height: 1;
    text-align: center;
    text-decoration: none;

    height: 90px;
    width: 100px;

    margin: 0 5px;
    
    &:hover {
      color: ${getColor('accentGreen')};
      transform: scale(1.1);
    }

    img {
      height: 45px;
      width: 45px;
      
      margin: 0;
    }

    span {
      display: block;

      font-size: 1.3rem;
      font-weight: ${getFontWeight('bold')};
      text-transform: capitalize;
    }
  }
`;

const Header = () => {
  const drawMenu = items => {
    return items.map(item => {
      const { external, icon, name, text, url } = item;

      return external ? (
        <a key={name} href={url} target="_blank" rel="noopener noreferrer">
          <img src={icon} alt={name} />
          <span>{text}</span>
        </a>
      ) : (
        <Link key={name} to={url}>
          <img src={icon} alt={name} />
          <span>{text}</span>
        </Link>
      );
    });
  };

  return (
    <HeaderWrapper>
      <HeaderContainer>
        <Link to="/">
          <img src={othlLogo} alt="OneTrust Home Loans" />
        </Link>
        <Menu>{drawMenu(menuItems)}</Menu>
      </HeaderContainer>
    </HeaderWrapper>
  );
};

export default Header;
