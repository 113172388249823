import React from 'react';
import styled from 'styled-components/macro';

// import assets
import ehl from '../images/ehl.png';
import facebook from '../images/icons/otm_facebook.png';
import linkedin from '../images/icons/otm_linkedin.png';
import instagram from '../images/icons/otm_instagram.png';
import twitter from '../images/icons/xicon.png';

// import components
import { Grid } from './Grid';

// import helpers
import { getBreakpoint, getColor } from '../util/theme';

// data to feed into footer so that it knows all the social icon links to draw - done this way to be easily updated/take new additions
const socialLinks = [
  {
    name: 'facebook',
    url: 'https://www.facebook.com/OneTrustHomeLoans',
    icon: facebook,
  },
  {
    name: 'linkedin',
    url: 'https://www.linkedin.com/company/onetrust-home-loans',
    icon: linkedin,
  },
  {
    name: 'instagram',
    url: 'https://www.instagram.com/onetrusthomeloans',
    icon: instagram,
  },
  {
    name: 'twitter',
    url: 'https://www.twitter.com/OneTrustHL',
    icon: twitter,
  },
];

// footer container styled component
// IE11 doesn't respect it getting pushed to the bottom, will have to look for polyfill or something someday...or not...
const FooterMain = styled.footer`
  border-top: 3px solid ${getColor('accentGreen')};
  background-color: ${getColor('primaryBlue')};
  color: ${getColor('white')};
  width: 100%;

  margin: 50px 0 0 0;
  padding: 25px 0;

  /* pushes the footer to the bottom */
  position: absolute;
  top: 100%;

  @media (min-width: ${getBreakpoint('md')}) {
    margin: 25px 0 0 0;
  }
`;

const FooterContainer = styled(Grid)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;

  @media (min-width: ${getBreakpoint('lg')}) {
    flex-direction: row;
  }

  p {
    font-size: 1.2rem;
  }
`;

const FooterLeft = styled.div`
  flex-basis: 20%;

  p {
    display: inline-block;
  }

  a {
    color: ${getColor('white')};
    text-decoration: none;

    &:hover {
      color: ${getColor('accentGreen')};
    }
  }
`;

const FooterCenter = styled.div`
  flex-basis: 40%;

  display: flex;
  align-items: center;
  justify-content: space-around;

  img {
    height: 50px;
    width: 50px;

    margin: 0 15px 0 0;

    object-fit: contain;

    flex-basis: 10%;
  }

  p {
    flex-basis: 90%;
  }
`;

const SocialIcon = styled.img`
  border: 3px solid ${getColor('primaryBlue')};
  border-radius: 50%;

  width: 38px;
  margin: 0 10px;

  &:hover {
    border: 2px solid ${getColor('accentGreen')};
  }
`;
const year = new Date().getFullYear();

const Footer = () => (
  // a lot of stuff is hard-coded in here since it's the footer and doesn't need to change much
  <FooterMain>
    <FooterContainer>
      <FooterLeft>
        <p>
          © {year} OneTrust Home Loans
          <br />
          <a
            href="https://onetrusthomeloans.com/licensing-information/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Licensing
          </a>
          &nbsp;|&nbsp;
          <a
            href="http://onetrusthomeloans.com/privacy-policy/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Privacy
          </a>
        </p>
      </FooterLeft>
      <FooterCenter>
        <img src={ehl} alt="Equal HousingLender" />
        <p>
          CalCon Mutual Mortgage LLC, dba OneTrust Home Loans is an Equal
          Housing Lender NMLS #46375 (www.nmlsconsumeraccess.org); 3838 Camino
          del Rio N Suite 305, San Diego, CA 92108. Corporate phone (888)
          488-3807.
        </p>
      </FooterCenter>
      <div>
        {// might break this out into its own method one day
        socialLinks.map(link => {
          return (
            <a
              key={link.name}
              href={link.url}
              alt={link.name}
              target="_blank"
              rel="noopener noreferrer"
            >
              <SocialIcon src={link.icon} />
            </a>
          );
        })}
      </div>
    </FooterContainer>
  </FooterMain>
);

export default Footer;
